






import Vue from "vue";
import Layout from "@/router/layouts/main.vue";

export default Vue.extend({
  name: "az-homepage" as string,

  components: {
    Layout
  }

})
